import React from 'react';
import PreviousNews from './PreviousNews';
import NextNews from './NextNews';

const NewsNavigation = ({ categoryId, newsId }) => {
  return (
    <div className="news-navigation">
      <div className="previous-news">
        <PreviousNews categoryId={categoryId} newsId={newsId} />
      </div>
      <div className="ads">
        <h3>тут могла быть реклама, но ее нет</h3>
      </div>
      <div className="next-news">
        <NextNews categoryId={categoryId} newsId={newsId}/>
      </div>
    </div>
  );
};

export default NewsNavigation;
