import React from 'react';
import { Helmet } from 'react-helmet';
import Menu from './Menu';
import LatestNews from './LatestNews';
import TopViewed from './TopViewed';


function Home() {
  return (
    <div className="container">
      {/* Meta Tags */}
      <Helmet>
      <title>ByteBeat - актуальные новости мира информационных технологий</title>
        <meta name="description" content="ByteBeat - ваши актуальные новости из мира информационных технологий. Качественные и быстрые информационные обновления о последних тенденциях в мире IT на русском языке." />
        <meta name="DC.title" content="ByteBeat - актуальные новости мира информационных технологий" />
        <meta name="DC.subject" content="Новости, публикации" />
        <meta name="DC.description" content="ByteBeat - ваши актуальные новости из мира информационных технологий. Качественные и быстрые информационные обновления о последних тенденциях в мире IT на русском языке." />
        <meta name="DC.type" content="Статьи" />
        <meta name="DC.format" content="Блог" />
      </Helmet>

      {/* Left Sidebar with Menu */}
      <div className="sidebar">
        <Menu />
      </div>

      {/* Main Content Area */}
      <div className="content">
        <TopViewed />
        <h1>Последние публикации:</h1>
        <LatestNews />
      </div>
    </div>
  );
}

export default Home;
