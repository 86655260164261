import React, { useState, useEffect } from 'react';

const NextNews = ({ categoryId, newsId }) => {
  const [nextNews, setNextNews] = useState(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchNextNews = async () => {
      try {
        const response = await fetch(`https://api.bytebeat.ru/api/news/${categoryId}/next/${newsId}`);
  
        if (response.ok) {
          const data = await response.json();
          setNextNews(data);
        } else {
          // Check for JSON message
          if (response.headers.get('Content-Type').includes('application/json')) {
            const data = await response.json();
            if (data.message) {
              setNextNews({ message: data.message }); // Store message as "nextNews"
            }
          } else if (response.status !== 404) {
            throw new Error('Failed to fetch');
          }
        }
      } catch (error) {
        console.error('Error fetching next news:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchNextNews();
  }, [categoryId, newsId]);

  const handleNextNewsClick = () => {
    if (nextNews) {
      const { link, id } = nextNews;
      window.location.href = `${link}`;
    }
  };

  return (
    <div>
      <h3>Следующая публикация:</h3>
      {loading ? (
        <p>Loading...</p>
      ) : nextNews ? (
        nextNews.message ? ( // Check for message property
          <p>{nextNews.message}</p>
        ) : (
          <p><li onClick={handleNextNewsClick}>{nextNews.title} →</li></p>
        )
      ) : (
        <p>Ошибка при получении данных</p> // Generic error message
      )}
    </div>
  );
};

export default NextNews;
