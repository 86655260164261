import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import CategoryNews from './components/CategoryNews';
import NewsDetail from './components/NewsDetail'; // Import NewsDetail component
import ScrollToTopButton from "./components/ScrollToTopButton";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NotFound from './components/NotFound'; // Импортируем компонент NotFound
import { Helmet } from 'react-helmet';



function App() {
  return (
    <Router>
      <div>
        <Helmet>
        <meta name="DC.language" content="ru" />
        <link rel="canonical" href="https://bytebeat.ru/" />
        <meta name="DC.creator" content="bytebeat" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta charset="utf-8" />

        </Helmet>
       
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/category/:categoryName" element={<CategoryNews />} />
          <Route path="/category/:categoryName/:id/:link" element={<NewsDetail />} /> {/* Route for NewsDetail */}
          <Route path="/category/*" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/category/:categoryName/:id/*" element={<NotFound />} /> {/* Route for NewsDetail */}
          {/* New Route for NextNews */}
          {/* Handle sitemap.xml directly */}
          {/* <Route path="/sitemap.xml" element={<Sitemap />} /> */}
        </Routes>
        <ScrollToTopButton />
        <Footer />
      </div>
    </Router>
    
  );
}

export default App;
