import React, { useState, useEffect } from 'react';

// Reusable component for rendering a single news item
const NewsItem = ({ news, onClick }) => (
  <li key={news.id} onClick={() => onClick(news.id, news.link)}>
    {news.title} →
  </li>
);

const PreviousNews = ({ categoryId, newsId }) => {
  const [previousNews, setPreviousNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // State for error messages

  useEffect(() => {
    const fetchPreviousNews = async () => {
      setLoading(true);
      setError(null); // Clear previous errors

      try {
        const response = await fetch(`https://api.bytebeat.ru/api/news/${categoryId}/previous/${newsId}`);

        if (response.ok) {
          const contentType = response.headers.get('Content-Type');

          if (contentType && contentType.includes('application/json')) {
            const data = await response.json();

            if (data.message) {
              setPreviousNews({ message: data.message });
            } else if (Array.isArray(data)) {
              setPreviousNews(data.slice(0, 3));
            } else {
              setError('Invalid data format received from server.');
            }
          } else {
            setError('Unexpected response format.');
          }
        } else {
          setError('Failed to fetch previous news.');
        }
      } catch (error) {
        setError('Error fetching previous news.'); // Generic error message
      } finally {
        setLoading(false);
      }
    };

    fetchPreviousNews();
  }, [categoryId, newsId]);

  const handlePreviousNewsClick = async (id, link) => {
    try {
      // Переходим к предыдущей новости с использованием id и link
      window.location.href = `${link}`;
    } catch (error) {
      console.error('Error fetching previous news:', error);
    }
  };

  return (
    <div>
      <h3>Предыдущие публикации:</h3>
      {loading ? (
        'Загрузка...'
      ) : error ? (
        <p>{error}</p> // Display error message
      ) : (
        <span>
          {previousNews.length > 0 || previousNews.message ? ( // Check if there are previous news or message
            <span>
              {Array.isArray(previousNews) ? (
                previousNews.map((news) => (
                 <p key={news.id}>
                    <NewsItem news={news} onClick={handlePreviousNewsClick} />
                  </p> 
                ))
              ) : (
                <p>{previousNews.message}</p> // Render message if present
              )}
            </span>
          ) : (
            <p>Нет предыдущих записей</p>
          )}
        </span>
      )}
    </div>
  );
};

export default PreviousNews;
