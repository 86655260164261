import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import RelevantNews from "./RelevantNews";

function Menu() {
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.bytebeat.ru/api/news/categories"
        );
        // Сортируем список категорий по идентификаторам
        const sortedCategories = response.data.sort((a, b) => a.id - b.id);
        setCategories(sortedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    // При изменении маршрута обновляем активную категорию
    const currentCategory = categories.find(category =>
      location.pathname.includes(category.name)
    );
    setActiveCategory(currentCategory);
  }, [location.pathname, categories]);

  return (
    <div className="menu-container">
      <ul className="menu-list">
        <li className="menu-item">
          <Link to="https://bytebeat.ru/" className="menu-link">
           Главная
          </Link>
          <hr className="horizontal-rule" />
        </li>
      </ul>
      <div className="menu-title"></div>
      <ul className="menu-list" style={{ marginTop:'-20px'}}>
        {categories.map((category) => (
          <li
            key={category.id}
            className={`menu-item ${activeCategory && activeCategory.id === category.id ? 'active' : ''}`}
            onClick={() => setActiveCategory(category)}
          >
            <Link to={`https://bytebeat.ru/category/${category.name}`} className="menu-link">
              {category.name_ru}
            </Link>
          </li>
        ))}
      </ul>
      <RelevantNews />
    </div>
  );
}

export default Menu;
