import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import loadingImg from "./loading.png";

function LatestNews() {
  const [articles, setArticles] = useState([]);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showLoadingImage, setShowLoadingImage] = useState(true);

  const fetchData = async (offset = 0) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `https://api.bytebeat.ru/api/news/categories/latest?limit=30&offset=${offset}`
      );
      const newArticles = response.data;
      setArticles([...articles, ...newArticles]);
      setHasMore(newArticles.length === 30);
    } catch (error) {
      // Отображаем ошибку в консоли, но не выводим на экран
      console.error("Произошла ошибка при загрузке данных:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleLoadMore = () => {
    setLoadMoreLoading(true);
    fetchData(offset + 30)
      .then(() => {
        setOffset(offset + 30);
        setLoadMoreLoading(false);
      })
      .catch((error) => {
        console.error("Ошибка загрузки данных:", error);
        setLoadMoreLoading(false);
      });
  };

  const truncateAltText = (tags, maxLength) => {
    if (!Array.isArray(tags)) {
      return tags;
    }
    const formattedText = tags.join(", ");
    if (formattedText.length > maxLength) {
      const truncatedText = formattedText.substring(0, maxLength);
      const lastSpaceIndex = truncatedText.lastIndexOf(" ");
      const finalText = truncatedText.substring(0, lastSpaceIndex) + "...";
      return finalText;
    } else {
      return formattedText;
    }
  };
  const formattedDate = (dateString) => {
    const options = { 
      day: '2-digit', 
      month: '2-digit', 
      year: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit' 
    };
    return new Date(dateString).toLocaleString('ru-RU', options);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setShowLoadingImage((prev) => !prev);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="latest-news-container">
      {!isLoading && articles.length === 0 && <div>Новостей не найдено.</div>}
      {isLoading && articles.length === 0 && <div>Загрузка...</div>}
      {/* Отображаем ошибку только если она не равна null */}
      {error !== null && <div>Произошла ошибка при загрузке данных. Пожалуйста, попробуйте еще раз.</div>}
      {articles.length > 0 && (
        <div className="flex-column category-wrapper-main">
          <div className="flex-row">
            {articles[0] && (
              <div className="article-container" style={{ width: "50%" }}>
                <Link to={`${articles[0].link}`}>
                  <h3>{articles[0].title}</h3>
                </Link>
                <p className="article-date">
                    <span style={{ textDecoration: 'underline' }}  >

                      <Link to={`/category/${articles[0].category}`}>
                        {articles[0].category_ru}
                      </Link>
                      </span>
                      {/* Удалите Link вокруг этого <p> */}
                      {"   "} {/* Пробел добавлен сюда */}
                      {formattedDate(articles[0].date_time)}

                    </p>

                <div>
                  <Link to={`${articles[0].link}`}>
                    <img
                      src={articles[0].image_url}
                      alt={truncateAltText(articles[0].tags, 100)}
                      className="first-article-image"
                      style={{ width: "100%", height: "auto" }}
                    />
                  </Link>
                </div>
              </div>
            )}

            <div className="flex-column" style={{ width: "50%" }}>
              {articles.slice(1, 4).map((article) => (
                <div key={article.id} className="article-container">
                  <Link to={`${article.link}`}>
                    <h3>{article.title}</h3></Link>
                    <p className="article-date">
                    <span style={{ textDecoration: 'underline' }}  >

                      <Link to={`/category/${article.category}`}>
                        {article.category_ru}
                      </Link>
                      </span>
                      {/* Удалите Link вокруг этого <p> */}
                      {"   "} {/* Пробел добавлен сюда */}
                      {formattedDate(article.date_time)}
                    </p>
                </div>
              ))}
            </div>
          </div>

          {articles.slice(4).map((article, index) => (
            <div key={`${article.id}_${index}`} className="article-container">
              <Link to={`${article.link}`}>

              </Link>
              <div className="article-details">
                <h3>
                <Link to={`${article.link}`}>
                    {article.title}
                  </Link>
                  </h3>
                <div className="flex-row">
                  <div className="article-image-container">
                    <img
                      src={article.image_url}
                      alt={article.tags}
                      className="article-image"
                    />
                  </div>
                  <div className="article-content">
                    <p className="article-date">
                    <span style={{ textDecoration: 'underline' }}  >

                      <Link to={`/category/${article.category}`}>
                        {article.category_ru}
                      </Link>
                      </span>
                      {/* Удалите Link вокруг этого <p> */}
                      {"   "} {/* Пробел добавлен сюда */}
                      {formattedDate(article.date_time)}

                    </p>
                    <p className="article-description">
                      {article.short_description}
                    </p>
                    {/* Добавляем кнопку "Читать полностью" */}
         <Link to={`${article.link}`}>
      <button className="read-more-button">Читать полностью</button>
    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="load-more-container">
            {hasMore && (
              <button
                className="button"
                onClick={handleLoadMore}
                disabled={loadMoreLoading}
              >
                {loadMoreLoading ? <p>Загрузка...</p> : <p>Показать еще</p>}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default LatestNews;
