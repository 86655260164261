import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

function RelevantNews() {
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const newsContainerRef = useRef(null); // Реф для контейнера с новостью

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await axios.get(
          "https://api.bytebeat.ru/api/news/categories/relevant"
        );
        const latestArticles = response.data;
        setArticles(latestArticles);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error fetching data. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  function scrollToNews() {
    newsContainerRef.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div className="relevant-news-container" ref={newsContainerRef}>
      {!isLoading && articles.length === 0 && <div>Новостей не найдено.</div>}
      {isLoading && articles.length === 0 && <div>Загрузка...</div>}
      {error && <div>Ошибка: {error}</div>}
      {articles.length > 0 && (
        <div>
          <h2>Актуальное</h2>
          {articles.map((article, index) => (
            <div
              key={`${article.id}_${index}`}
              className="article-item"
              onClick={() => scrollToNews()}
            >
              <Link to={`${article.link}`}>
                <div className="image-container">
                  <img
                    src={article.image_url}
                    //src="/news_images/1.jpg"
                    alt={article.title.substring(0, 40)}
                  />
                </div>
              </Link>
              <div className="article-date">
                <p>{new Date(article.date_time).toLocaleDateString()}</p>
                <Link to={`${article.link}`}>
                  <h3 className="article-title">{truncateText(article.title, 100)}</h3>
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  } else {
    return text;
  }
}

export default RelevantNews;
