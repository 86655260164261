import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Menu from './Menu';
import { Breadcrumb } from "react-bootstrap";

function NotFound() {
  const { categoryName } = useParams(); // Получаем название категории из URL
  const [articles, setArticles] = useState([]); // Список статей
  const [category, setCategory] = useState(null); // Информация о категории
  const [isLoading, setIsLoading] = useState(true); // Индикатор загрузки
  const [error, setError] = useState(null); // Сообщение об ошибке
  const [offset, setOffset] = useState(0); // Смещение для подгрузки данных
  const [hasMore, setHasMore] = useState(true); // Флаг наличия дополнительных данных

  // Ссылка на кнопку "Показать больше"
  const loadMoreButtonRef = useRef(null);


  const formattedDate = (dateString) => {
    const options = { 
      day: '2-digit', 
      month: '2-digit', 
      year: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit' 
    };
    return new Date(dateString).toLocaleString('ru-RU', options);
  };

  // Функция для получения данных
  const fetchData = async (offset = 0) => {
    setIsLoading(true);
    setError(null);
    try {
      // Получаем список категорий
      const categoriesResponse = await axios.get(
        "https://api.bytebeat.ru/api/news/categories/relevant"
      );
      const categories = categoriesResponse.data;

      // Находим текущую категорию
      const foundCategory = categories.find((cat) => cat.name === categoryName);
      setCategory(foundCategory);

      // Получаем список статей для текущей категории
      const articlesResponse = await axios.get(
        `https://api.bytebeat.ru/api/news/categories/relevant`
      );
      const newArticles = articlesResponse.data;

      // Обновляем список статей
      setArticles((prevArticles) =>
        offset === 0 ? newArticles : [...prevArticles, ...newArticles]
      );

      // Проверяем, есть ли ещё данные для подгрузки
      setHasMore(newArticles.length === 30);
    } catch (error) {
      console.error("Ошибка при получении данных:", error);
      setError("Ошибка при получении данных. Пожалуйста, попробуйте позже.");
    } finally {
      setIsLoading(false);
    }
  };



  // Эффект для загрузки данных при изменении категории
  useEffect(() => {
    setArticles([]);
    setOffset(0);
    fetchData();
  }, [categoryName]);



  return (
    <div className="container">
      {/* Meta Tags */}
      <Helmet>
        <title>ByteBeat - актуальные новости мира информационных технологий | Страница не найдена</title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content="ByteBeat - ваши актуальные новости из мира информационных технологий. Качественные и быстрые информационные обновления о последних тенденциях в мире IT на русском языке." />
        <meta name="DC.title" content="ByteBeat - актуальные новости мира информационных технологий | Страница не найдена" />
        <meta name="DC.creator" content="bytebeat" />
        <meta name="DC.subject" content="Новости, публикации" />
        <meta name="DC.description" content="ByteBeat - ваши актуальные новости из мира информационных технологий. Качественные и быстрые информационные обновления о последних тенденциях в мире IT на русском языке." />
        <meta name="DC.type" content="Статьи" />
        <meta name="DC.format" content="Блог" />
        <meta name="DC.language" content="ru" />
        <link rel="canonical" href="https://bytebeat.ru/" />
      </Helmet>

      {/* Left Sidebar with Menu */}
      <div className="flex-row">
        <div className="menu-container">
          <Menu />
        </div>

        {/* Main Content Area */}
        <div className="content-container">
          {!isLoading && articles.length === 0 && <div> Новостей не найдено.</div>}
          {isLoading && articles.length === 0 && <div>Загрузка...</div>}
          {error && <div>Ошибка: {error}</div>}
          <div className="flex-column category-wrapper">
            <Breadcrumb className="breadcrumb-container">
              <Breadcrumb.Item href="/">Главная</Breadcrumb.Item>
              <Breadcrumb.Item active>{category && category.name_ru}</Breadcrumb.Item>
            </Breadcrumb>
            <h2 className="category-title">{category && category.name_ru}</h2>
            <hr className="horizontal-rule" />
            <h1> Страница не найдена.</h1>
            <hr className="horizontal-rule" />
            <h2> Посмотрите актуальные публикации:</h2>

            {articles.map((article, index) => (
              <div key={article.id} className="article-container">
                <Link to={`${article.link}`}>
                  <h3>{article.title}</h3>
                </Link>
                <div className="flex-row">
                  <div className="article-image-container">
                    <img
                      src={article.image_url}
                      alt={article.tags}
                      className="article-image"
                    />
                  </div>
                  <div className="article-content">
                    <p className="article-date"><span style={{ textDecoration: 'underline' }}>
                    <Link  to={`https://bytebeat.ru/category/${article.category}`}>
                      {article.category_ru}
                    </Link></span>{" "}{formattedDate(article.date_time)}
                    </p>
                    <p className="article-description">
                      {article.short_description}
                    </p>
                    <Link to={`${article.link}`}>
                      <button className="read-more-button">Читать полностью</button>
                    </Link>
                  </div>
                </div>
                <hr className="horizontal-rule" />
                <div className="tags-container">
                  Tags:{" "}
                  {article.tags.map((tag, index) => (
                    <span key={index}>
                      {tag}
                      {index !== article.tags.length - 1 && ","}{" "}
                    </span>
                  ))}
                </div>
              </div>
            ))}
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
