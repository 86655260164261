import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams, Link, Navigate } from "react-router-dom"; // Добавляем Navigate для редиректа
import { Breadcrumb } from "react-bootstrap";
import Menu from "./Menu"; // Предполагается, что у вас есть компонент Menu
import { Helmet } from 'react-helmet';

function CategoryNews() {
  const { categoryName } = useParams(); // Получаем название категории из URL
  const [articles, setArticles] = useState([]); // Список статей
  const [category, setCategory] = useState(null); // Информация о категории
  const [isLoading, setIsLoading] = useState(true); // Индикатор загрузки
  const [error, setError] = useState(null); // Сообщение об ошибке
  const [offset, setOffset] = useState(0); // Смещение для подгрузки данных
  const [hasMore, setHasMore] = useState(true); // Флаг наличия дополнительных данных
  const [loadMoreLoading, setLoadMoreLoading] = useState(false); // Индикатор загрузки при подгрузке
  const loadMoreButtonRef = useRef(null); // Ссылка на кнопку "Показать больше"

  // Формируем мета-описание
  const metaDescription = `ByteBeat | ${category?.name_ru} | ${category?.description}`;

  // Функция для форматирования даты
  const formattedDate = (dateString) => {
    const options = { 
      day: '2-digit', 
      month: '2-digit', 
      year: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit' 
    };
    return new Date(dateString).toLocaleString('ru-RU', options);
  };

  // Функция для получения данных
  const fetchData = async (offset = 0) => {
    setIsLoading(true);
    setError(null);
    try {
      const categoriesResponse = await axios.get("https://api.bytebeat.ru/api/news/categories");
      const categories = categoriesResponse.data;
      const foundCategory = categories.find((cat) => cat.name === categoryName);

      if (foundCategory) {
        const articlesResponse = await axios.get(
          `https://api.bytebeat.ru/api/news/${categoryName}?limit=30&offset=${offset}`
        );
        const newArticles = articlesResponse.data;

        setArticles((prevArticles) =>
          offset === 0 ? newArticles : [...prevArticles, ...newArticles]
        );

        setHasMore(newArticles.length === 30);
      } else {
        setError("Категория не найдена.");
      }
      setCategory(foundCategory);
    } catch (error) {
      console.error("Ошибка при получении данных:", error);
      setError("Ошибка при получении данных. Пожалуйста, попробуйте позже.");
    } finally {
      setIsLoading(false);
    }
  };

  // Эффект для загрузки данных при изменении категории
  useEffect(() => {
    setArticles([]);
    setOffset(0);
    fetchData();
  }, [categoryName]);

  // Обработчик нажатия на кнопку "Показать больше"
  const handleLoadMore = () => {
    setLoadMoreLoading(true);
    fetchData(offset + 30)
      .then(() => {
        setOffset(offset + 30);
        setLoadMoreLoading(false);
      })
      .catch((error) => {
        console.error("Ошибка загрузки данных:", error);
        setLoadMoreLoading(false);
      });
  };

  // Если данные о категории не получены, выполняем редирект на страницу NotFound
  if (!category && !isLoading) {
    return <Navigate to="/not-found" />;
  }

  return (
    <div className="container">
      <Helmet>
        <title>{`IT: ByteBeat | ${category?.name_ru}`}</title>
        <meta name="description" content={metaDescription} />
        <meta name="DC.title" content={`IT: ByteBeat | ${category?.name_ru}`} />
        <meta name="DC.description" content={metaDescription} />
        <meta name="DC.type" content="Публикации" />
      </Helmet>
      <div className="flex-column">
        <div className="flex-row">
          <div className="menu-container">
            <Menu />
          </div>
          <div className="content-container">
            {!isLoading && articles.length === 0 && <div> Новостей не найдено.</div>}
            {isLoading && articles.length === 0 && <div>Загрузка...</div>}
            {error && <div>Ошибка: {error}</div>}
            {articles.length > 0 && (
              <div className="flex-column category-wrapper">
                <Breadcrumb className="breadcrumb-container">
                  <Breadcrumb.Item href="/">Главная</Breadcrumb.Item>
                  <Breadcrumb.Item active>{category.name_ru}</Breadcrumb.Item>
                </Breadcrumb>
                <h2 className="category-title">{category.name_ru}</h2>
                <hr className="horizontal-rule" />
                <p className="category-description">{category.description}</p>
                <hr className="horizontal-rule" />
                {articles.map((article, index) => (
                  <div key={article.id} className="article-container">
                    <Link to={`${article.link}`}>
                      <h3>{article.title}</h3>
                    </Link>
                    <div className="flex-row">
                      <div className="article-image-container">
                        <img
                          src={article.image_url}
                          alt={article.tags}
                          className="article-image"
                        />
                      </div>
                      <div className="article-content">
                        <p className="article-date">{formattedDate(article.date_time)}</p>
                        <p className="article-description">{article.short_description}</p>
                        <Link to={`${article.link}`}>
                          <button className="read-more-button">Читать полностью</button>
                        </Link>
                      </div>
                    </div>
                    <hr className="horizontal-rule" />
                    <div className="tags-container">
                      Tags:{" "}
                      {article.tags.map((tag, index) => (
                        <span key={index}>
                          {tag}
                          {index !== article.tags.length - 1 && ","}{" "}
                        </span>
                      ))}
                    </div>
                  </div>
                ))}
                {hasMore && (
                  <div className="load-more-container">
                    <button
                      ref={loadMoreButtonRef}
                      onClick={handleLoadMore}
                      disabled={loadMoreLoading}
                      className="button"
                    >
                      {loadMoreLoading ? <p>Загрузка...</p> : <p>Показать еще</p>}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoryNews;
