import React from 'react';

function Footer() {
  return (
    <footer className="container-fluid bg-dark text-light p-3 mt-5">
      <div className="row">
        {/* Левая часть - Текст */}
        <div className="col-lg-4 d-flex align-items-center justify-content-center">
          <p className="text-center" style={{ fontSize: '14px', width: '1350px' }}>
            Не является СМИ. Сайт создан с использованием технологий искусственного интеллекта. Материалы также прошли обработку ИИ. Изображения личностей может несоответствовать их реальному внешнему виду
          </p>
        </div>

        {/* Средняя часть - Партнеры */}
        <div className="col-lg-4 d-flex flex-column-footer align-items-center justify-content-center">
          <p className="text-light mb-1">Партнеры:</p>
          
          <a href="https://qater.ru" target='_blank' rel="noreferrer" alt="QAter - тестируем мир с уверенностью!" className="text-light mt-1">QAter</a>
        </div>

        {/* Правая часть - Логотип и ссылка */}
        <div className="col-lg-4 d-flex flex-column-footer align-items-center justify-content-center">
          {/* Замените 'logo.png' на путь к вашему логотипу */}
          <a href="https://bytebeat.ru">
          <img src="logo-footer.png" alt="ByteBeat - актуальные новости мира IT" style={{ maxWidth: '150px', border: '2px solid white', boxShadow: '0px 0px 5px 0px rgba(255, 255, 255, 0.75)' }} /> 
         </a>
          <a href="https://bytebeat.ru/sitemap.xml" target='_blank' rel="noopener" alt="Карта сайта" className="text-light mt-2">Карта сайта</a>
        </div>
        <p className="mb-0 text-center" style={{ color: '#fff' }}>© 2024 ByteBeat</p>

      </div>

    
    </footer>
  );
}

export default Footer;
