import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Navigate } from "react-router-dom"; 
import { Breadcrumb } from "react-bootstrap";
import Menu from "./Menu";
import DOMPurify from "dompurify";
import { Helmet } from 'react-helmet';
import NewsNavigation from "./NewsNavigation";

function NewsDetail() {
  const { categoryName, id } = useParams();
  const [article, setArticle] = useState(null);
  const [category, setCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [articleExists, setArticleExists] = useState(true); 
  const [categoryExists, setCategoryExists] = useState(true); 

  const metaDescription = `ByteBeat | ${category?.name_ru} | ${article?.short_description}`;


  const formattedDate = (dateString) => {
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };
    return new Date(dateString).toLocaleString('ru-RU', options);
  };

  function truncate(text, maxLength = 90) {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.substring(0, maxLength) + '...';
    }
  }

  const createMarkup = (html) => {
    const sanitizedHtml = DOMPurify.sanitize(html, {
      ADD_TAGS: ["img"],
      ADD_ATTR: ["src", "alt", "style"]
    });

    const processedHtml = sanitizedHtml.replace(/<img /g, '<img style="max-width: 1024px; height: auto "');
    return { __html: processedHtml };
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const articleResponse = await axios.get(`https://api.bytebeat.ru/api/news/${categoryName}/${id}`);
        setArticle(articleResponse.data);

        const categoriesResponse = await axios.get("https://api.bytebeat.ru/api/news/categories");
        const categories = categoriesResponse.data;
        const foundCategory = categories.find((cat) => cat.name === categoryName);
        setCategory(foundCategory);

        await axios.post(`https://api.bytebeat.ru/api/news/${categoryName}/${id}/view`);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error fetching data. Please try again later.");
        if (error.response && error.response.status === 404) {
          setArticleExists(false);
        }
        if (error.response && error.response.status === 404 && error.response.data === "Категория не найдена.") {
          setCategoryExists(false); 
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [categoryName, id]);

  // Проверяем, существует ли категория или статья, если нет, перенаправляем на страницу NotFound
  if (!isLoading && (!articleExists || !categoryExists)) {
    return <Navigate to="/not-found" />;
  }

  return (
    <div className="container">
      <Helmet>
        <title>{`ByteBeat | ${category?.name_ru} | ${article?.title}`}</title>
        <meta name="description" content={metaDescription} />
        <meta name="DC.title" content={`ByteBeat | ${category?.name_ru} | ${article?.title}`} />
        <meta name="DC.description" content={metaDescription} />
        <meta name="DC.type" content="Публикации" />
      </Helmet>
      <div className="flex-column">
        <div className="container">
          <div className="menu-container">
            <Menu />
          </div>
          <div className="article-container-details">
            {isLoading && <div>Загрузка...</div>}
            {error && <div>Error: {error}</div>}
            {article && category && (
              <div className="flex-column category-wrapper-detail">
                <Breadcrumb>
                  <Breadcrumb.Item href="/">Главная</Breadcrumb.Item>
                  <Breadcrumb.Item href={`https://bytebeat.ru/category/${categoryName}`}>
                    {category.name_ru}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active title={article.title}>{truncate(article.title)}</Breadcrumb.Item>
                </Breadcrumb>

                <h3 className="details-title">{article.title}</h3>
                {article.date_time && (
                  <p>Дата публикации: {formattedDate(article.date_time)}</p>
                )}
                {article.image_url && (
                  <img src={article.image_url} alt={article.tags} style={{ maxWidth: '1024px' }} />
                )}
                <div dangerouslySetInnerHTML={createMarkup(article.full_description)} /> 
                <div className="tags-container-detail">
                  Tags: 
                  {article.tags.map((tag, index) => (
                    <span key={index} className="tag">
                      {tag}
                      {index !== article.tags.length - 1 && ","}
                    </span>
                  ))}
                <hr style={{marginTop: '20px'}}
                className="horizontal-rule" />

                </div>
                <NewsNavigation categoryId={categoryName} newsId={id}  />

              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsDetail;
