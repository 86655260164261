import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

function TopViewed() {
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          "https://api.bytebeat.ru/api/news/categories/top-viewed",
          { validateStatus: status => status === 200 || status === 304 } // Позволяет обрабатывать статус 304
        );
        const latestArticles = response.data.map(article => ({
          ...article,
          // Проверяем статус ответа, если не 200, то используем заглушку
          image_url: response.status === 200 ? article.image_url : "/news_images/sample-image.png"
        }));
        setArticles(latestArticles);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error fetching data. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);


  return (

    <div className="top-viewed">
      <div className="articles-container">
        {articles.map((article, index) => (
          <div key={`${article.id}_${index}`} className="article-card">
            <Link to={`${article.link}`}>
              <img
                src={article.image_url}
                alt={article.title.substring(0, 40)}
                className="article-image"
              />
            </Link>
            <div>
              <Link to={`${article.link}`}>
                <h3 className="article-title">{article.title}</h3>
              </Link>
              {/* ... (Rest of the article info) */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TopViewed;
