import React from 'react';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isScrolled: false,
      currentDateTime: this.getCurrentDateTime(), // получаем текущую дату и время
      showTimeLabel: true // показывать ли надпись "Текущее время"
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.intervalID = setInterval(
      () => this.tick(),
      1000 // обновляем время каждую секунду
    );
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    clearInterval(this.intervalID);
  }

  getCurrentDateTime() {
    const months = ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'];
    const currentDate = new Date();
    const day = currentDate.getDate();
    const monthIndex = currentDate.getMonth();
    const year = currentDate.getFullYear();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const formattedDate = `${day} ${months[monthIndex]} ${year}`;
    const formattedTime = `${hours}:${minutes}`;
    return `${formattedDate} ${formattedTime}`;
  }

  handleScroll = () => {
    const scrollTop = window.scrollY;
    const isScrolled = scrollTop > 0;
    this.setState({ isScrolled });

    // При прокрутке скрываем надпись "Текущее время"
    if (scrollTop > 0) {
      this.setState({ showTimeLabel: false });
    } else {
      this.setState({ showTimeLabel: true });
    }
  };

  tick() {
    this.setState({
      currentDateTime: this.getCurrentDateTime()
    });
  }

  render() {
    const { isScrolled, currentDateTime, showTimeLabel } = this.state;

    return (
      <header className={`container-fluid bg-dark p-3 header-container ${isScrolled ? 'scrolled' : ''}`}>
        <div className="left-block">
          <div className={`logo-container ${isScrolled ? 'small' : ''}`}>
          <a href="https://bytebeat.ru">
            <img src="/logo.png" alt="ByteBeat - актуальные новости мира IT" className="logo"/>
            </a>
          </div>
        </div>
        <div className="center-block">
          {/* Здесь может быть ваш центральный блок */}
        </div>
        <div className="right-block">
          <div className="time-container">
            <span className="current-time" style={{alignContent:'center'}}>
              {showTimeLabel && <div>Время сервера: Moscow UTC+3</div>}
              {currentDateTime}
            </span>
          </div>
          {/* Показываем надпись "Текущее время", если showTimeLabel равно true */}
          
        </div>
      </header>
    );
  }
}

export default Header;
